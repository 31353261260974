import type { AxiosResponse } from 'axios';
import type { Context } from '@nuxt/types';
import { getAccessToken, getFullUrl, getSubId } from '~/utils/index';
import { isAnyRecipePage } from './router';
import AuthWindow from '~/components/Auth/AuthWindow.vue';
import { pushBadge } from '~/components/Pusher/websockets-functions';
import { useArticlesStore } from '~/store/articles';
import { useFoldersStore } from '~/store/folders';
import { useIndexStore } from '~/store';
import { usePageStore } from '~/store/pagesData';
import { useModal } from '@devhacker/shared/plugins/modal/useModal';

type Post = {
  title: string;
  url: string;
  id: number;
  type?: string;
};

// в данный момент идет работа с избранным
let isManagingFavorites = false;
// отложить добавление в избранное
export function favoriteDeferred(deferredAction: Function, ctx: any) {
  useModal().open(
    AuthWindow,
    {
      loginCallback: deferredAction.bind(ctx),
      eventElement: 'Избранное',
    },
    {
      transition: 'modal-fade',
      // disableScroll: true,
    },
  );
}

// удалить избранное
export function favoriteDelete(
  favorite: IFavoriteItem,
  post: Post,
  ctx: any,
  sendGTM: boolean = true,
  cb: Function = () => {},
): void {
  if (isManagingFavorites) {
    return;
  }
  isManagingFavorites = true;

  return ctx.$authApi
    .removeArticleFromFavorites(favorite.id, getSubId(), getAccessToken())
    .then((resp: AxiosResponse) => {
      if (resp.data.success) {
        useArticlesStore().removeFavorite(post.id);
        if (sendGTM) {
          sendRemoveFavoriteEvent(post, ctx);
        }
        const title = post ? ` <b>${post.title}</b> ` : ' ';
        const notificationMessage =
          isAnyRecipePage(ctx._route.name) || post.type === 'recipe'
            ? `Рецепт${title}удален из избранного`
            : `Статья${title}удалена из избранного`;
        return pushBadge({
          content: notificationMessage,
        });
      }
    })
    .then(() => {
      return useFoldersStore().getFolders();
    })
    .then(() => {
      cb();
    })
    .finally(() => {
      isManagingFavorites = false;
    });
}

// добавить статью в папку избранного
export function favoriteAddToFolder(
  folder: IFolderItem,
  post: Post,
  sendGTM: boolean = true,
  ctx: any,
  isNewFolder: boolean = false,
): any {
  if (isManagingFavorites) {
    return;
  }
  isManagingFavorites = true;

  return ctx.$authApi
    .addArticleToFavorites(post.id, folder.id, getSubId(), getAccessToken())
    .then((resp: AxiosResponse) => {
      if (resp.data.success) {
        const { data } = resp.data;

        useArticlesStore().addFavorite(data);

        if (!isNewFolder && sendGTM) {
          sendAddFavoriteEvent(post, ctx);
        }

        const title = post ? ` <b>${post.title}</b> ` : ' ';
        const notificationMessage =
          isAnyRecipePage(ctx._route.name) || post.type === 'recipe'
            ? `Рецепт${title}добавлен в папку <b>${folder.name}</b>`
            : `Статья${title}добавлена в папку <b>${folder.name}</b>`;
        return pushBadge({
          content: notificationMessage,
          clickHandler: () => {
            // window.location.href = process.env.WORDPRESS_API_URL + '/profile/favorites';

            // Check if this state updates correctly, before was taken from user property directly
            const userId = useIndexStore().userIntegerId;
            if (userId) {
              location.href = `/profile/user/${userId}/favorites`;
            }
          },
        });
      }
    })
    .then(() => {
      return useFoldersStore().getFolders();
    })
    .finally(() => {
      isManagingFavorites = false;
    });
}

// добавить папку к избранному и добавить в нее пост
export function favoriteCreateFolderAndAdd(
  folderName: string,
  post: Post,
  ctx: any,
  sendGTM: boolean = true,
  cb: Function = () => {},
) {
  if (isManagingFavorites) {
    return;
  }
  isManagingFavorites = true;

  return ctx.$authApi
    .addFolder(folderName, getSubId(), getAccessToken())
    .then((resp: AxiosResponse) => {
      if (resp.data.success) {
        const { data: folder } = resp.data;

        isManagingFavorites = false;

        if (sendGTM) {
          sendAddFavoriteInNewFolderEvent(post, ctx);
        }

        favoriteAddToFolder(folder, post, false, ctx, true).then(() => {
          cb();
        });
      }
    });
}

// добавить папку к избранному и перенести в нее пост
export function favoriteCreateFolderAndTransfer(
  folderName: string,
  favorite: IFavoriteItem,
  post: Post,
  _: void,
  ctx: any,
  cb: Function = () => {},
) {
  if (isManagingFavorites) {
    return;
  }
  isManagingFavorites = true;

  return ctx.$authApi
    .addFolder(folderName, getSubId(), getAccessToken())
    .then((resp: AxiosResponse) => {
      if (resp.data.success) {
        const { data: folder } = resp.data;

        isManagingFavorites = false;
        favoriteTransferToFolder(favorite, folder, post, undefined, ctx).then(() => {
          cb();
        });
      }
    });
}

// перенести избранное из одной папки в другую
export function favoriteTransferToFolder(
  favorite: IFavoriteItem,
  folder: IFolderItem,
  post: Post,
  _: void,
  ctx: any,
  cb: Function = () => {},
): any {
  if (isManagingFavorites) {
    return;
  }
  isManagingFavorites = true;
  const articlesStore = useArticlesStore();

  return ctx.$authApi
    .patchFavorite(favorite.id, folder.id, getSubId(), getAccessToken())
    .then((resp: AxiosResponse) => {
      if (resp.data.success) {
        const { data: favorite } = resp.data;
        cb();

        articlesStore.removeFavorite(favorite.post_id);
        articlesStore.addFavorite(favorite);

        const title = post ? ` <b>${post.title}</b> ` : ' ';
        const notificationMessage =
          isAnyRecipePage(ctx._route.name) || post.type === 'recipe'
            ? `Рецепт${title}перемещен в папку <b>${folder.name}</b>`
            : `Статья${title}перемещена в папку <b>${folder.name}</b>`;

        return pushBadge({
          content: notificationMessage,
          clickHandler: () => {
            // window.location.href = process.env.WORDPRESS_API_URL + '/profile/favorites';

            // Check if this state updates correctly, before was taken from user property directly
            const userId = useIndexStore().userIntegerId;
            if (userId) {
              location.href = `/profile/user/${userId}/favorites`;
            }
          },
        });
      }
    })
    .then(() => {
      return useFoldersStore().getFolders();
    })
    .finally(() => {
      isManagingFavorites = false;
    });
}

// ------------------------
// аналитика для избранного
// ------------------------
// https://youtrack.lifehacker.ru/issue/LH-894
// Добавление материала в избранное
// Добавление поста в избранное в кастомную/общую папку
const sendAddFavoriteEvent = (post: Post, ctx: Context): void => {
  ctx.$sendYandexMetrika({
    level1: 'Избранное_добавление избранного',
    level4: getFullUrl(post.url),
    // @ts-ignore
    level6: usePageStore().currentTitle ?? 'unknown',
  });
};

// Удаление поста из избранного
const sendRemoveFavoriteEvent = (post: Post, ctx: Context): void => {
  ctx.$sendYandexMetrika({
    level1: 'Избранное_удаление избранного',
    level4: getFullUrl(post.url),
    // @ts-ignore
    level6: usePageStore().currentTitle ?? 'unknown',
  });
};

// Добавление поста в новую папку
// когда папка создана перед добавлением поста в избранное
const sendAddFavoriteInNewFolderEvent = (post: Post, ctx: Context): void => {
  ctx.$sendYandexMetrika({
    level1: 'Избранное_добавление избранного',
    level4: getFullUrl(post.url),
    // @ts-ignore
    level6: usePageStore().currentTitle ?? 'unknown',
  });
};
